.names {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;

    &__list {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        max-height: 95vh;
        max-width: 95vw;
        min-width: 95vw;
        width: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 320px));
        justify-content:space-evenly;
        justify-items: stretch;
        align-items: stretch;
        align-content: stretch;
        gap: 10px;

        overflow-y: auto;

        background-color: rgb(22, 42, 50, 0.85);
        box-shadow: 0 0 20px #000000;
        padding-top: 40px;
        padding-bottom: 40px;
        color: #DADADA;
        font-size: 14px;
    }

    &__item {
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-self: stretch;
        padding: 10px;
        cursor: pointer;
        transition: all ease 0.2s;
        border: 1px solid rgb(22, 42, 50, 0.85);
        min-height: 60px;

        &:hover {
            background-color: #00000065;
        }

        &__current {
            color: #93E751;
            border: 1px solid #92e75196;
            background-color: #00000028;


        }

    }

    &__close {
        &__wrapper {
            position: absolute;
            width: 32px;
            height: 32px;
            right: 20px;
            top: 20px;

        }
        position: fixed;
        // right: 10px;
        // top: 10px;
        width: 32px;
        height: 32px;
        background-image: url(../assets/img/check-circle.svg);
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
            transform: scale(1.2);

        }

        &:active {
            transform: scale(0.9);

        }
    }
}