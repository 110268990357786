#root {
    width: 100%;
    height: 100%;
}

.app {
    position: relative;
    display: flex;
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background-image: radial-gradient(#264653, #0F1112);
    justify-content: center;
    align-items: center;
}

.currency {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    text-align: center;
    &__link {
        align-self: center;
        justify-self: center;
        padding: 5px 12px;
        background: #396D82;
        color: #ffffff;
        font-weight: 800;
        font-size: 24px;
        text-align: center;
        width: max-content;
        margin-bottom: 20px;
        cursor: pointer;
        transition: color ease 0.2s;
        &:hover {
            color: #93E751;
        }

    }
    &__symbol{
        margin-right: 5px;
    }
    &__date {
        color: #ffffff;
        font-size: clamp(10px, 14px, 16px);
        padding-left: 20px;
        padding-right: 20px;

    }
    &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
    }
    &__letters {
        color: #101112;
        font-size: clamp(256px, 720px, 50vw);
        font-weight: 900;
        opacity: 0.2;   
        overflow: hidden;
        text-align: center;
        position: absolute;
        text-align: center;
        margin: 0 auto;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);

    }
    &__actual {
        // font-size:172px;
        font-size: clamp(10vw, 172px, 25vw);
        font-weight: 900;
        
    }
    &__previous {
        font-size: clamp(5vw, 48px, 64px);
        font-weight: 800;
    }
    &__up {
        color: #E76F51;
    }
    &__down {
        color: #93E751;
    }
}